import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'
import logoo from "../assets/img/logoo.png";

function About() {
  return (
    <div>

  <div className="back-to-top"></div>

  <header>
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 text-sm">
            
          </div>
          <div className="col-sm-4 text-right text-sm">
          
          </div>
        </div> 
      </div> 
    </div> 

    <nav className="navbar navbar-expand-lg navbar-light shadow-sm">
      <div className="container">
      <img className="logoo" src={logoo} alt="" width={80} height={80} />
        <a className="navbar-brand" href="/"><span className="text-primary">Espace numérique</span>-de santé</a>

      

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupport" aria-controls="navbarSupport" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupport">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">A propos</a>
            </li>
           
          </ul>
        </div>
      </div> 
    </nav>
  </header>

 

  <div className="page-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 wow fadeInUp">
          <h1 className="text-center mb-3">LE ENS, QU’EST-CE QUE C’EST ?</h1>
          <div className="text-lg">
            <p>L'Espace numérique de santé (ENS) est un service qui vous permet d’accéder simplement et rapidement aux données de santé de votre patient : traitements, antécédents, allergies, etc.

                   <br>
                   </br>Le ENS est particulièrement utile pour les personnes ayant souvent recours aux soins comme les patients atteints d’une maladie chronique ou les femmes enceintes.</p>
           
          </div>
        </div>
        <div className="col-lg-10 mt-5">
          <h1 className="text-center mb-5 wow fadeInUp">Pourquoi ENS ?</h1>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 wow zoomIn">
              <div className="card-doctor">
                <div className="header">
                  <img src="https://www.netexplorer.fr/storage/app/uploads/public/870/690/e73/thumb_900_663_0_0_crop.jpg" alt=""/>
                 
                </div>
                <div className="body">
                  <p className="text-xl mb-0">Partage de  documents</p>
                
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 wow zoomIn">
              <div className="card-doctor">
                <div className="header">
                  <img src="https://thumbs.dreamstime.com/b/illustration-du-dialogue-entre-le-m%C3%A9decin-et-patient-sur-fond-bleu-d-un-au-bureau-204742234.jpg" alt=""/>
                  <div className="meta">
                 
                  </div>
                </div>
                <div className="body">
                  <p className="text-xl mb-0">Contact des docteurs</p>
                
                </div>
              </div>
            </div>
          
            <div className="col-md-6 col-lg-4 wow zoomIn">
              <div className="card-doctor">
                <div className="header">
                  <img src="https://us.123rf.com/450wm/teravector/teravector1903/teravector190300272/124518968-la-famille-de-vecteur-s-est-tourn%C3%A9e-pour-aider-le-m%C3%A9decin-p%C3%A9diatre-illustration-les-hommes-et-les-fe.jpg?ver=6" alt=""/>
                  <div className="meta">
                 
                  </div>
                </div>
                <div className="body">
                  <p className="text-xl mb-0">Consultation des dossiers</p>
                
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>



  
  <footer className="page-footer">
        <div className="container">
          <div className="row px-md-3">
            <div className="col-lg-9 col-lg-3 py-3">
             
              <ul className="footer-menu">
              <h5>Nos interfaces</h5>
                <li><a href="/">A propos</a></li>
                <li><a href="/docteur">Docteurs</a></li>
                <li><a href="/patient">Patients</a></li>
              </ul>
            </div>
         
            <div className="col-sm-6 col-lg-3 py-3">
              <h5>Contact</h5>
              <p className="footer-link mt-2">Bab Saadoun 1006 Tunis</p>
              <a href="/" className="footer-link">701-577-000</a>
              <a href="/" className="footer-link">espacenumeriquedesante@gmail.com</a>

              
            </div>
            
          </div>
          
          <hr />
         <p id="copyright">Tous les droits sont réservés &copy; 2022 </p>
        </div>
      </footer>

      {/* <script src="../assets/js/jquery-3.5.1.min.js"></script>

<script src="../assets/js/bootstrap.bundle.min.js"></script>

<script src="../assets/vendor/owl-carousel/js/owl.carousel.min.js"></script>

<script src="../assets/vendor/wow/wow.min.js"></script>

<script src="../assets/js/theme.js"></script> */}



    </div>
  )
}

export default About