import React from 'react'
import logo from "../assets/img/logo.png";

function Contact() {
  return (
    <div className='Contact'>
  <div className="back-to-top"></div>

  <header>
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 text-sm">
       
          </div>
          <div className="col-sm-4 text-right text-sm">
          
          </div>
        </div> 
      </div> 
    </div> 
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm">
      <div className="container">
      <img className="logo-abbr" src={logo} alt="" width={80} height={80} />
      <a className="navbar-brand" href="/"><span className="text-primary">Espace numérique</span>-de santé</a>

        <form action="#">
          <div className="input-group input-navbar">
           
          
          </div>
        </form>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupport" aria-controls="navbarSupport" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupport">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/index">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/doctors">Professionnels de santé</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blog">Patients</a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/contact">Contact</a>
            </li>
           
          </ul>
        </div> 
      </div> 
    </nav>
  </header>

  {/* <div className="page-banner overlay-dark bg-image" style="background-image: url(../assets/img/bg_image_1.jpg);"> */}
  <div className="page-banner overlay-dark bg-image">
    <div className="banner-section">
      <div className="container text-center wow fadeInUp">
        <nav aria-label="Breadcrumb">
          <ol className="breadcrumb breadcrumb-dark bg-transparent justify-content-center py-0 mb-2">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Contact</li>
          </ol>
        </nav>
        <h1 className="font-weight-normal">Contact</h1>
      </div> 
    </div> 
  </div> 

  <div className="page-section">
    <div className="container">
      <h1 className="text-center wow fadeInUp">Contacter-nous</h1>

      <form className="contact-form mt-5">
        <div className="row mb-3">
          <div className="col-sm-6 py-2 wow fadeInLeft">
            <label for="fullName">Nom et prénom</label>
            <input type="text" id="fullName" className="form-control" placeholder="Full name.."/>
          </div>
          <div className="col-sm-6 py-2 wow fadeInRight">
            <label for="emailAddress">Adress Email</label>
            <input type="text" id="emailAddress" className="form-control" placeholder="Email address.."/>
          </div>
          <div className="col-12 py-2 wow fadeInUp">
            <label for="subject">Sujet</label>
            <input type="text" id="subject" className="form-control" placeholder="Enter subject.."/>
          </div>
          <div className="col-12 py-2 wow fadeInUp">
            <label for="message">Message</label>
            <textarea id="message" className="form-control" rows="8" placeholder="Enter Message.."></textarea>
          </div>
        </div>
        <button type="submit" className="btn btn-primary wow zoomIn">Envoyer </button>
      </form>
    </div>
  </div>

  <div className="maps-container wow fadeInUp">
    <div id="google-maps"></div>
  </div>

  {/* <div className="page-section banner-home bg-image" style="background-image: url(../assets/img/banner-pattern.svg);"> */}
  <div className="page-section banner-home bg-image">
    <div className="container py-5 py-lg-0">
      <div className="row align-items-center">
        <div className="col-lg-4 wow zoomIn">
          <div className="img-banner d-none d-lg-block">
            <img src="../assets/img/mobile_app.png" alt=""/>
          </div>
        </div>
        <div className="col-lg-8 wow fadeInRight">
          <h1 className="font-weight-normal mb-3">......</h1>
          <a href="/"><img src="../assets/img/google_play.svg" alt=""/></a>
          <a href="/" className="ml-2"><img src="../assets/img/app_store.svg" alt=""/></a>
        </div>
      </div>
    </div>
  </div> 

  <footer className="page-footer">
        <div className="container">
          <div className="row px-md-3">
            <div className="col-sm-6 col-lg-3 py-3">
              <h5>Nos interfaces</h5>
              <ul className="footer-menu">
                <li><a href="/">A propos</a></li>
                <li><a href="/doctors">Docteurs</a></li>
                <li><a href="/blog">Patients</a></li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-3 py-3">
              <h5>Inscription</h5>
              <ul className="footer-menu">
               
                <li><a href="/">S'inscrire comme docteur</a></li>
                <li><a href="/">S'inscrire comme patient</a></li>
              </ul>
            </div>
          
            <div className="col-sm-6 col-lg-3 py-3">
              <h5>Contact</h5>
              <p className="footer-link mt-2">Bab Saadoun 1006 Tunis</p>
              <a href="/" className="footer-link">701-577-000</a>
              <a href="/" className="footer-link">healthcare@gmail.com</a>

              
            </div>
            
          </div>
          
          <hr />

          <p id="copyright">Copyright &copy; 2022 All right reserved</p>
        </div>
      </footer>



    </div>
  )
}

export default Contact